import * as React from 'react';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ArticleIcon from '@mui/icons-material/Article';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import { useNavigate } from 'react-router-dom';
import GroupsIcon from '@mui/icons-material/Groups';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LockResetIcon from '@mui/icons-material/LockReset';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Kids } from '../../helpers/people';
import ROLES from '../../helpers/roles';
import { FirebaseContext } from '../../contexts/Firebase';
import { AuthenticationContext } from '../../contexts/Authentication';
import { ADMIN, CHANGE_PASSWORD, LIST, LOGIN, PEOPLE, PERSON } from '../../helpers/routes';

const NavDrawer = () => {
  const { name, role, user } = React.useContext(AuthenticationContext);
  const firebase = React.useContext(FirebaseContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));

  const [open, setOpen] = React.useState(false);

  const handleSignOut = () => {
    firebase.signOut();
    navigate(LOGIN);
  };

  const handleToggle = () => {
    setOpen(!open);
  }

  const goTo = (destination) => {
    handleToggle();
    navigate(destination);
  };

  const menuOptions = () => {
    if (!user) {
      return (
        <List>
          <ListItem key="Sign In" disablePadding>
            <ListItemButton onClick={() => goTo(LOGIN)}>
              <ListItemIcon>
                <LoginIcon />
              </ListItemIcon>
              <ListItemText primary="Sign In" />
            </ListItemButton>
          </ListItem>
        </List>
      )
    }

    return (
      <>
        <List>
          {role === ROLES.ADMIN ? (
            <ListItem key="Admin Panel" disablePadding>
              <ListItemButton onClick={() => goTo(ADMIN)}>
                <ListItemIcon>
                  <AdminPanelSettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Admin Panel" />
              </ListItemButton>
            </ListItem>
          ) : null}
          {Kids.includes(name) ? null : (
            <ListItem key="My Person" disablePadding>
              <ListItemButton onClick={() => goTo(PERSON)}>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="My Person" />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem key="All Lists" disablePadding>
            <ListItemButton onClick={() => goTo(PEOPLE)}>
              <ListItemIcon>
                <GroupsIcon />
              </ListItemIcon>
              <ListItemText primary="All Lists" />
            </ListItemButton>
          </ListItem>
          <ListItem key="My List" disablePadding>
            <ListItemButton onClick={() => goTo(LIST)}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="My List" />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem key="Change Password" disablePadding>
            <ListItemButton onClick={() => goTo(CHANGE_PASSWORD)}>
              <ListItemIcon>
                <LockResetIcon />
              </ListItemIcon>
              <ListItemText primary="Change Password" />
            </ListItemButton>
          </ListItem>
          <ListItem key="Sign Out" disablePadding>
            <ListItemButton onClick={handleSignOut}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Sign Out" />
            </ListItemButton>
          </ListItem>
        </List>
      </>
    );
  }

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        aria-controls="navigation-menu"
        aria-haspopup={true}
        onClick={handleToggle}
        size="large"
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor={desktop ? "right" : "top"}
        variant="temporary"
        open={open}
        onClose={handleToggle}
      >
        {menuOptions()}
      </Drawer>
    </>
  );
};

export default NavDrawer;