import React, { useContext, useEffect, useState } from 'react';

import convertToArray from '../helpers/list';
import { FirebaseContext } from './Firebase';

const UsersContext = React.createContext(null);

const UsersProvider = ({ children }) => {
  const firebase = useContext(FirebaseContext);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    firebase.users().once('value').then(data => {
      setUsers(convertToArray(data))
    });
  }, [firebase]);

  return (
    <UsersContext.Provider value={ users }>
      {children}
    </UsersContext.Provider>
  )
};

export { UsersContext, UsersProvider };