
import Grid from '@mui/material/Grid';
import { Navigate } from 'react-router-dom';
import SimpleCrypto from 'simple-crypto-js';
import React, { useContext, useEffect, useState } from 'react';

import { LOGIN } from '../../helpers/routes';
import { KEY, Kids } from '../../helpers/people';
import ItemList from '../../components/List/ItemList';
import Loading from '../../components/Shared/Loading';
import Typography from '@mui/material/Typography';
import { FirebaseContext } from '../../contexts/Firebase';
import { AuthenticationContext } from '../../contexts/Authentication';

const Person = () => {
  const firebase = useContext(FirebaseContext);
  const { authenticating, user, name: userName } = useContext(AuthenticationContext);

  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const crypto = new SimpleCrypto(KEY);

  useEffect(() => {
    if (user && Kids.includes(userName)) {
      setLoading(false);
    } else if (user && !Kids.includes(userName)) {
      firebase.match(userName).once('value').then(data => {
        setName(crypto.decrypt(data.val()));
        setLoading(false);
      });
    }
  }, [crypto, firebase, user, userName]);

  if (authenticating || loading) {
    return <Loading />
  }

  if (!authenticating && user && Kids.includes(userName)) {
    return (
      <Grid container item xs={12}>
        <Typography variant={'h5'}>
          Kids don't have a matched person.
        </Typography>
      </Grid>
    );
  }

  if (!authenticating && !user) {
    return <Navigate to={LOGIN} />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant={'h5'}>
          My Person: {name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ItemList name={name} />
      </Grid>
    </Grid>
  );
};

export default Person;