import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

import ActionDialog from './ActionDialog';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    textAlign: 'right',
    padding: `${theme.spacing(2)} 0`,
  },
}));

const Actions = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const onFinish = result => {
    setOpen(false);
    setMessage(result);
  };

  const classes = useStyles();
  return (
    <Grid className={classes.root} container>
      {
        message && (
          <Grid item xs={12}>
            <Typography variant={'subtitle1'}>
              {message}
            </Typography>
          </Grid>
        )
      }
      <Grid item xs={12}>
        <Button variant="contained" onClick={() => setOpen(true)}> Match </Button>
        <ActionDialog open={open} setOpen={setOpen} onFinish={onFinish}/>
      </Grid>
    </Grid>
  );
};

export default Actions;