import React, { useState, useContext } from 'react';

import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import { FirebaseContext } from '../../contexts/Firebase';

const useStyles = makeStyles( () => ({
  firstInput: {
    marginTop: 0,
  },
}));

const Change = () => {
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const [error, setError] = useState(null);
  const [passwordOne, setPasswordOne] = useState('');
  const [passwordTwo, setPasswordTwo] = useState('');
  const isInvalid = passwordOne === '' || passwordTwo === '';

  const submit = event => {
    event.preventDefault();
    firebase.updatePassword(passwordOne)
            .then(() => {
              setPasswordOne('');
              setPasswordTwo('');
            })
            .catch(error => setError(error));
  };

  return (
    <React.Fragment>
      <h3>Change Password</h3>
      <TextField
        id='password'
        type='password'
        label='Password'
        value={passwordOne}
        onChange={event => setPasswordOne(event.target.value)}
        margin='normal'
        variant='outlined'
        placeholder='New Password'
        fullWidth
        className={classes.firstInput}
      />
      <TextField
        id='confirm'
        type='password'
        label='Confirm Password'
        value={passwordTwo}
        onChange={event => setPasswordTwo(event.target.value)}
        margin='normal'
        variant='outlined'
        placeholder='Confirm New Password'
        fullWidth
      />
      {error && <p>{error.message}</p>}
      <FormControl component={'div'} fullWidth>
        <Button
          color='primary'
          href='#'
          onClick={submit}
          variant='contained'
          disabled={isInvalid}
        >
          Update Password
        </Button>
      </FormControl>
    </React.Fragment>
  );
};

export default Change;