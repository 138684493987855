import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';

import Item from './Item';
import convertToArray from '../../helpers/list';
import { ItemContext } from '../../contexts/Item';
import { FirebaseContext } from '../../contexts/Firebase';

const useStyles = makeStyles(theme => ({
  edit: {
    textAlign: 'right',
  },
  empty: {
    padding: `${theme.spacing(3)} 0`,
  },
  root: {
    padding: theme.spacing(2),
    width: '100%',
    margin: `${theme.spacing(1)} 0`,
  }
}));

const ItemList = ({ name, editable = false }) => {
  const firebase = useContext(FirebaseContext);
  const { deleteItem, openEdit } = useContext(ItemContext);

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    firebase.list(name).on('value', data => {
      setList(convertToArray(data));
      setLoading(false);
    });
  }, [firebase, name]);

  const classes = useStyles();

  return (
    <Grid container item xs={12}>
      {
        !loading && !list.length && (
          <Grid className={classes.empty} container item xs={12}>
            <Typography variant={'h6'}> There's nothing in the list! </Typography>
          </Grid>
        )
      }
      { list.map(item => {
        const { description, id, link, title } = item;
        return (
          <Paper className={classes.root} key={id}>
            <Item
              description={description}
              edit={editable}
              link={link}
              title={title}
            />
            { editable && (
              <Grid container>
                <Grid item xs={6}>
                  <Button color="secondary" onClick={() => deleteItem(id)}>Delete</Button>
                </Grid>
                <Grid className={classes.edit} item xs={6}>
                  <Button onClick={() => openEdit(item)}>Edit</Button>
                </Grid>
              </Grid>
            )}
          </Paper>
        )
      })}
    </Grid>
  );
};

export default ItemList;