
import Grid from '@mui/material/Grid';
import { Navigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import React, { useContext, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

import { LOGIN } from '../../helpers/routes';
import { PeopleMenu } from '../../helpers/people';
import ItemList from '../../components/List/ItemList';
import Loading from '../../components/Shared/Loading';
import { AuthenticationContext } from '../../contexts/Authentication';

const People = () => {
  const { authenticating, user, } = useContext(AuthenticationContext);

  const [name, setName] = useState('Aaron');

  if (authenticating) {
    return <Loading />
  }

  if (!authenticating && !user) {
    return <Navigate to={LOGIN} />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormControl component={'div'} variant="outlined" fullWidth>
          <Select
            fullWidth
            variant='outlined'
            multiple={false}
            value={name}
            input={<OutlinedInput labelWidth={0} name="name" id="name"/>}
            onChange={event => setName(event.target.value)}
          >
            {PeopleMenu}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <ItemList name={name} />
      </Grid>
    </Grid>
  );
};

export default People;