import { FirebaseContext } from './Firebase';
import React, { useContext, useEffect, useState } from 'react';

const AuthenticationContext = React.createContext(null);

const AuthenticationProvider = ({ children }) => {
  const firebase = useContext(FirebaseContext);

  const [auth, setAuth] = useState({
    authenticating: true,
    name: null,
    role: null,
    user: null,
  });

  useEffect(() => {
    firebase.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        firebase.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            setAuth({
              authenticating: false,
              name: snapshot.val().name,
              user: authUser,
              role: snapshot.val().role,
            });
          });
      } else {
        setAuth({
          authenticating: false,
          user: null,
          role: null,
        });
      }
    });
  }, [firebase]);

  return (
    <AuthenticationContext.Provider value={ auth }>
      {children}
    </AuthenticationContext.Provider>
  )
};

export { AuthenticationContext, AuthenticationProvider };