import * as React from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import ArticleIcon from '@mui/icons-material/Article';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PersonIcon from '@mui/icons-material/Person';

import * as ROUTES from '../../helpers/routes';
import ItemDialog from '../List/ItemDialog';
import { useContext } from 'react';
import { ItemContext } from '../../contexts/Item';

const BottomButtons = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { openDialog } = useContext(ItemContext);

  const [value, setValue] = React.useState(location);

  React.useEffect(() => {
    setValue(location);
  }, [location]);

  const onClick = (destination) => {
    if (destination === ROUTES.ADD) {
      openDialog();
    } else {
      navigate(destination);
    }
  };

  return (
    <>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => onClick(newValue)}
      >
        <BottomNavigationAction label="My Person" value={ROUTES.PERSON} icon={<PersonIcon />} />
        <BottomNavigationAction label="Add Item" value={ROUTES.ADD} icon={<AddIcon />} />
        <BottomNavigationAction label="My List" value={ROUTES.LIST} icon={<ArticleIcon />} />
      </BottomNavigation>
      <ItemDialog />
    </>
  )
}

export default BottomButtons;