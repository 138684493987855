import Grid from '@mui/material/Grid';
import { Navigate } from 'react-router-dom';
import React, { useContext } from 'react';

import { LOGIN } from '../../helpers/routes';
import ItemList from '../../components/List/ItemList';
import Loading from '../../components/Shared/Loading';
import Typography from '@mui/material/Typography';
import { AuthenticationContext } from '../../contexts/Authentication';

const List = () => {
  const { authenticating, user, name } = useContext(AuthenticationContext);

  if (authenticating) {
    return <Loading />
  }

  if (!authenticating && !user) {
    return <Navigate to={LOGIN} />;
  }

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Typography variant={'h5'}>
          My List
        </Typography>
      </Grid>
      <ItemList name={name} editable={true} />
    </Grid>
  );
};

export default List;