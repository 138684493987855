const convertToArray = (data) => {
  const arrayData = [];
  if (data.val()) {
    Object.keys(data.val()).forEach((key, index) => {
      const user = {
        id: key,
        ...data.val()[key],
      };
      arrayData.push(user);
    });
  }

  return arrayData;
};

export default convertToArray;
