import React from 'react';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
  anchor: {
    overflow: 'hidden',
    padding: `0 ${theme.spacing(1.75)}`,
    textOverflow: 'ellipsis',
  },
  link: {
    padding: `${theme.spacing(1.75)} 0`,
  },
  linkLabel: {
    color: 'rgba(0, 0, 0, 0.38)',
    padding: `0 ${theme.spacing(1.75)}`,
  },
  root: {
    padding: theme.spacing(2),
    width: '100%',
    margin: `${theme.spacing(1)} 0`,
  },
  '@global': {
    '.MuiOutlinedInput-input.Mui-disabled': {
      color: theme.palette.text.primary,
      '-webkit-text-fill-color': theme.palette.text.primary,
    },
    '.MuiOutlinedInput-root.Mui-disabled > fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    }
  }
}));

const Item = ({ description, link, title, setDescription, setLink, setTitle, readOnly = true }) => {
  const classes = useStyles();
  return (
    <Grid container item xs={12}>
      <TextField
        id='title'
        label='Item'
        value={title}
        onChange={event => setTitle(event.target.value)}
        margin='normal'
        variant='outlined'
        fullWidth
        placeholder={"Name of the thing!"}
        disabled={readOnly}
        required={!readOnly}
        InputLabelProps={{sx: {zIndex: 'auto'}}}
      />
      <TextField
        id='description'
        label='Description'
        value={description}
        onChange={event => setDescription(event.target.value)}
        margin='normal'
        variant='outlined'
        fullWidth
        multiline
        placeholder={"Give a description of the thing. If the thing is specific, be sure to explain that here."}
        disabled={readOnly}
        InputLabelProps={{sx: {zIndex: 'auto'}}}
      />
      {
        readOnly && !link && ''
      }
      {
        readOnly && link &&
          <FormControl className={classes.link}>
            <FormLabel className={classes.linkLabel}>
              <Typography variant={'caption'}>
                Link
              </Typography>
            </FormLabel>
            <Link className={classes.anchor} target={'_blank'} href={link} variant={'subtitle1'}>
              See the thing by clicking here!
            </Link>
          </FormControl>
      }
      { !readOnly &&
          <TextField
            id='link'
            label='Link'
            value={link}
            onChange={event => setLink(event.target.value)}
            margin='normal'
            variant='outlined'
            fullWidth
            placeholder={"If the thing can be found somewhere specific, include a url link to it here."}
            disabled={readOnly}
          />
      }
    </Grid>
  );
};

export default Item;