import React from 'react';
import { ACTIVATE } from '../../helpers/routes';
import { Link } from 'react-router-dom';


const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ACTIVATE}>Sign Up</Link>
  </p>
);

export default SignUpLink;