import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import React, { useContext, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import Item from './Item';
import { ItemContext } from '../../contexts/Item';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(theme => ({
  actions: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  }
}));

const ItemDialog = () => {
  const {
    description,
    edit,
    error,
    link,
    title,
    loading,
    open,
    save,
    setDescription,
    setLink,
    setOpen,
    setTitle,
    update,
  } = useContext(ItemContext);
  const [invalid, setInvalid] = useState(false);

  const classes = useStyles();

  const onSave = () => {
    setInvalid(false);
    if (!title) {
      setInvalid(true);
      return false;
    }

    if (edit) {
      update().then(() => setOpen(false));
    } else {
      save().then(() => setOpen(false));
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        { edit ? 'Edit Item' : 'Add Item' }
      </DialogTitle>
      <DialogContent>
        {
          invalid && (
            <Typography variant={'subtitle1'} color={'error'}>
              Please fill in the required fields. (*)
            </Typography>
          )
        }
        {
          error && (
            <Typography variant={'subtitle1'} color={'error'}>
              An error occurred while saving.
            </Typography>
          )
        }
        <Item
          description={description}
          link={link}
          title={title}
          readOnly={false}
          setDescription={setDescription}
          setLink={setLink}
          setTitle={setTitle}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button variant="contained" onClick={onSave}>
          { loading ? <CircularProgress color={'secondary'} size={14} /> : 'Save' }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemDialog;