import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import React, { useContext, useEffect, useState } from 'react';

import { Kids, People } from '../../helpers/people';
import { UsersContext } from '../../contexts/Users';
import { FirebaseContext } from '../../contexts/Firebase';
import convertToArray from '../../helpers/list';
import Chip from '@mui/material/Chip';

const useStyles = makeStyles(theme => ({
  chip: {
    margin: `0 ${theme.spacing(2)}`,
  },
  heading: {
    padding: theme.spacing(2),
    borderBottom: `1px solid rgba(0,0,0,0.27)`,
  },
  list: {
    width: '100%',
  },
  root: {
    width: '100%',
    margin: `${theme.spacing(1)} 0`,
  },
}));

export const determineInactives = (users) => (
  [...People, ...Kids].filter(name => !users.find(user => user.name === name))
);

const UserList = () => {
  const firebase = useContext(FirebaseContext);
  const users = useContext(UsersContext);

  const [inactives, setInactives] = useState([...People, ...Kids]);
  const [message, setMessage] = useState('');
  const [lists, setLists] = useState([]);

  useEffect(() => {
    setInactives(determineInactives(users));
  }, [users]);

  useEffect(() => {
    firebase.lists().once('value').then(data => setLists(convertToArray(data)));
  }, [firebase]);

  const classes = useStyles();

  const sendReset = user => {
    firebase.resetPassword(user.email).then(() => setMessage('Reset Sent'));
  };

  return (
    <React.Fragment>
      { message &&
        <Typography variant={'h6'}>
          {message}
        </Typography>
      }
      <Paper className={classes.root}>
        <Typography variant={'h6'} className={classes.heading}>
          Activated Accounts
        </Typography>
        <List className={classes.list}>
          {users.map((user) => (
            <React.Fragment key={user.id}>
              <ListItem alignItems={'center'}>
                <ListItemText
                  primary={user.name}
                />
                { !lists.find(list => list.id === user.name) && (
                  <Chip className={classes.chip} label={"NO LIST"} color={'secondary'} />
                )}
                <Button onClick={() => sendReset(user)}>
                  Send Reset
                </Button>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Paper>
      {inactives.length ? (
        <Paper className={classes.root}>
          <Typography variant={'h6'} className={classes.heading}>
            Inactive Accounts
          </Typography>
          <List className={classes.list}>
            {inactives.map((name) => (
              <React.Fragment key={name}>
                <ListItem alignItems={'flex-start'}>
                  <ListItemText
                    primary={name}
                  />
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Paper>
      ) : null}
    </React.Fragment>
  );
};

export default UserList;