import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useContext, useState } from 'react';

import ForgotLink from './ForgotLink';
import SignUpLink from './SignUpLink';
import { HOME } from '../../helpers/routes';
import { FirebaseContext } from '../../contexts/Firebase';
import { AuthenticationContext } from '../../contexts/Authentication';

const useStyles = makeStyles( () => ({
  firstInput: {
    marginTop: 0,
  },
  signUp: {
    textAlign: 'right',
  }
}));

const SignIn = () => {
  const firebase = useContext(FirebaseContext);
  const { user } = useContext(AuthenticationContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate(HOME);
    }
  }, [navigate, user]);

  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const isInvalid = password === '' || email === '';


  const signIn = () => {
    firebase.signInUserWithEmailAndPassword(email, password)
      .then(() => {
        navigate(HOME);
      })
      .catch(error => {
        setError(error);
      })
  }

  const submit = event => {
    event.preventDefault();
    signIn();
  };

  const keyboardSubmit = event => {
    event.preventDefault();
    if (event.key === 'Enter') {
      signIn();
    }
  }

  const onEmailChange = event => {
    setEmail(event.target.value);
    setError(null);
  };

  const onPasswordChange = event => {
    setPassword(event.target.value);
    setError(null);
  }

  return (
    <React.Fragment>
      <h3>Sign In</h3>
      <TextField
        id='email'
        label='Email'
        value={email}
        type='email'
        autoComplete='email'
        onChange={onEmailChange}
        onKeyUp={keyboardSubmit}
        margin='normal'
        variant='outlined'
        fullWidth
        className={classes.firstInput}
      />
      <TextField
        id='password'
        type='password'
        label='Password'
        value={password}
        onChange={onPasswordChange}
        onKeyUp={keyboardSubmit}
        margin='normal'
        variant='outlined'
        fullWidth
      />
      { error ? (
        <Alert sx={{ marginBottom: '0.5rem'}} severity="error">{ error.message }</Alert>
      ) : null}
      <Button
        color='primary'
        onClick={submit}
        fullWidth
        type='submit'
        variant='contained'
        disabled={isInvalid}
      >
        Sign In
      </Button>
      <Grid container>
        <Grid item xs={6}>
          <ForgotLink />
        </Grid>
        <Grid item xs={6} className={classes.signUp}>
          <SignUpLink />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SignIn;