import React from 'react';
import SimpleCrypto from "simple-crypto-js";
import MenuItem from '@mui/material/MenuItem';

export const KEY = 'rooke-family-secret-santa';

export const Names = {
  AARON: 'Aaron',
  BEN: 'Ben',
  CHRISTIANNE: 'Christianne',
  DENIS: 'Denis',
  GREG: 'Greg',
  IAN: 'Ian',
  JULIANNE: 'Julianne',
  KIRK: 'Kirk',
  LEILA: 'Leila',
  MAMA: 'Mama',
  BRE: 'Bre',
  NADINE: 'Nadine',
  PAPA: 'Papa',
  ZOE: 'Zoe',
};

export const People = [
  Names.AARON,
  Names.BEN,
  Names.CHRISTIANNE,
  Names.DENIS,
  Names.GREG,
  Names.IAN,
  Names.JULIANNE,
  Names.KIRK,
  Names.MAMA,
  Names.BRE,
  Names.PAPA,
];

export const Kids = [
  Names.NADINE,
  Names.LEILA,
  Names.ZOE,
];

export const PeopleMenu = [
  <MenuItem key={1} value={Names.AARON} primaryText={Names.AARON}>{Names.AARON}</MenuItem>,
  <MenuItem key={2} value={Names.BEN} primaryText={Names.BEN}>{Names.BEN}</MenuItem>,
  <MenuItem key={3} value={Names.CHRISTIANNE} primaryText={Names.CHRISTIANNE}>{Names.CHRISTIANNE}</MenuItem>,
  <MenuItem key={4} value={Names.DENIS} primaryText={Names.DENIS}>{Names.DENIS}</MenuItem>,
  <MenuItem key={5} value={Names.GREG} primaryText={Names.GREG}>{Names.GREG}</MenuItem>,
  <MenuItem key={6} value={Names.IAN} primaryText={Names.IAN}>{Names.IAN}</MenuItem>,
  <MenuItem key={7} value={Names.JULIANNE} primaryText={Names.JULIANNE}>{Names.JULIANNE}</MenuItem>,
  <MenuItem key={8} value={Names.KIRK} primaryText={Names.KIRK}>{Names.KIRK}</MenuItem>,
  <MenuItem key={9} value={Names.MAMA} primaryText={Names.MAMA}>{Names.MAMA}</MenuItem>,
  <MenuItem key={10} value={Names.BRE} primaryText={Names.BRE}>{Names.BRE}</MenuItem>,
  <MenuItem key={11} value={Names.PAPA} primaryText={Names.PAPA}>{Names.PAPA}</MenuItem>,
  <MenuItem key={12} value={Names.NADINE} primaryText={Names.NADINE}>{Names.NADINE}</MenuItem>,
  <MenuItem key={13} value={Names.LEILA} primaryText={Names.LEILA}>{Names.LEILA}</MenuItem>,
  <MenuItem key={13} value={Names.ZOE} primaryText={Names.ZOE}>{Names.ZOE}</MenuItem>,
];

const shuffle = () => {
  const result = [...People];
  result.sort(() => Math.random() -0.5);

  return result;
};

export const invalidMatch = (person, match) => {
  if (person === match) {
    return true;
  }

  if ((person === Names.BEN && match === Names.CHRISTIANNE)
      || (person === Names.CHRISTIANNE && match === Names.BEN)) {
    return true;
  }

  if ((person === Names.JULIANNE && match === Names.KIRK)
      || (person === Names.KIRK && match === Names.JULIANNE)) {
    return true;
  }

  if ((person === Names.DENIS && match === Names.BRE)
      || (person === Names.BRE && match === Names.DENIS)) {
    return true;
  }

  if ((person === Names.MAMA && match === Names.PAPA)
      || (person === Names.PAPA && match === Names.MAMA)) {
    return true;
  }

  return false;
};

const encryptMatches = (matches) => {
  const crypto = new SimpleCrypto(KEY);
  const result = [];
  for (let person in matches) {
    result[person] = crypto.encrypt(matches[person]);
  }

  return result;
};

export const getMatches = () => {
  let matches = [];
  let notMatched = true;

  while (notMatched) {
    const shuffled = shuffle();
    notMatched = false;
    for (let i = 0, len = People.length; i < len; i++) {
      if (invalidMatch(People[i], shuffled[i])) {
        notMatched = true;
      }
      matches[People[i]] = shuffled[i];
    }

    if (notMatched) {
      matches = [];
    }
  }

  return matches;
};

export const getEncryptedMatches = () => encryptMatches(getMatches());