import React, { useContext, useState } from 'react';

import { FirebaseContext } from './Firebase';
import { AuthenticationContext } from './Authentication';

const ItemContext = React.createContext(null);

const ItemProvider = ({ children }) => {
  const firebase = useContext(FirebaseContext);
  const { name } = useContext(AuthenticationContext);

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [id, setId] = useState('');
  const [link, setLink] = useState('');
  const [title, setTitle] = useState('');


  const openDialog = () => {
    setEdit(false);
    setDescription('');
    setLink('');
    setTitle('');
    setOpen(true);
  };

  const openEdit = (item) => {
    setItem(item);
    setEdit(true);
    setOpen(true);
  };

  const save = () => {
    setLoading(true);
    setError(false);
    const newItemRef = firebase.list(name).push();
    return newItemRef.set({
      title,
      description,
      link,
    }).catch(() => setError(true)).then(() => setLoading(false));
  };

  const deleteItem = (id) => {
    setLoading(true);
    firebase.item(name, id).remove().then(() => setLoading(false));
  };

  const update = () => {
    setLoading(true);
    setError(false);
    const itemRef = firebase.item(name, id);
    return itemRef.set({
      title,
      description,
      link,
    }).catch(() => setError(true)).then(() => setLoading(false));
  };

  const setItem = (item) => {
    setId(item.id);
    setTitle(item.title);
    setDescription(item.description);
    setLink(item.link);
  };

  return (
    <ItemContext.Provider
      value={{
        description,
        deleteItem,
        edit,
        error,
        id,
        link,
        title,
        loading,
        open,
        openEdit,
        openDialog,
        save,
        setDescription,
        setEdit,
        setError,
        setId,
        setItem,
        setLink,
        setOpen,
        setTitle,
        setLoading,
        update,
      }}>
      {children}
    </ItemContext.Provider>
  )
};

export { ItemContext, ItemProvider };