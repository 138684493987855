import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useContext, useRef } from 'react';

import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

import ROLES from '../../helpers/roles';
import { HOME } from '../../helpers/routes';
import { PeopleMenu } from '../../helpers/people';
import { FirebaseContext } from '../../contexts/Firebase';

const useStyles = makeStyles( theme => ({
  firstInput: {
    marginBottom: theme.spacing(1),
  },
}));

const SignUp = () => {
  const firebase = useContext(FirebaseContext);
  const navigate = useNavigate();

  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [isInvalid, setInvalid] = useState(true);
  const [labelWidth, setLabelWidth] = useState(0);
  const [passwordOne, setPasswordOne] = useState('');
  const [passwordTwo, setPasswordTwo] = useState('');
  const [userName, setUserName] = useState('');

  const inputLabel = useRef(null);

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const submit = () => {
    setError(null);
    firebase
      .createUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        return firebase
          .user(authUser.user.uid)
          .set({
            email,
            name: userName,
            role: ROLES.USER,
          });
      })
      .then(() => {
        navigate(HOME)
      })
      .catch(error => {
        setError(error);
      });
  };

  useEffect(() => {
    const isInvalid =
            passwordOne !== passwordTwo ||
            passwordOne === '' ||
            email === '' ||
            userName === '';
    setInvalid(isInvalid);
  }, [userName, email, passwordOne, passwordTwo]);

  return (
    <React.Fragment>
      <h3>Sign Up</h3>
      <FormControl component={'div'} variant="outlined" fullWidth className={classes.firstInput}>
        <InputLabel ref={inputLabel} htmlFor="name">
          Name
        </InputLabel>
        <Select
          fullWidth
          variant='outlined'
          multiple={false}
          value={userName}
          input={<OutlinedInput labelWidth={labelWidth} name="name" id="name"/>}
          onChange={event => setUserName(event.target.value)}
        >
          {PeopleMenu}
        </Select>
      </FormControl>
      <TextField
        id='email'
        label='Email'
        value={email}
        onChange={event => setEmail(event.target.value)}
        margin='normal'
        variant='outlined'
        fullWidth
      />
      <TextField
        id='password'
        type='password'
        label='Password'
        value={passwordOne}
        onChange={event => setPasswordOne(event.target.value)}
        margin='normal'
        variant='outlined'
        fullWidth
      />
      <TextField
        id='confirm'
        type='password'
        label='Confirm Password'
        value={passwordTwo}
        onChange={event => setPasswordTwo(event.target.value)}
        margin='normal'
        variant='outlined'
        fullWidth
      />
      {error && <p>{error.message}</p>}
      <FormControl component={'div'} fullWidth>
        <Button
          color='primary'
          href='#'
          onClick={submit}
          variant='contained'
          disabled={isInvalid}
        >
          Activate!
        </Button>
      </FormControl>
    </React.Fragment>
  );
};

export default SignUp;