import React from 'react';
import { Link } from 'react-router-dom';
import { FORGOT_PASSWORD } from '../../helpers/routes';


const ForgotLink = () => (
  <p>
    <Link to={FORGOT_PASSWORD}>Forgot Password?</Link>
  </p>
);

export default ForgotLink;