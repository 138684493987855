import React from 'react';
import { CssBaseline, Container, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import List from './Lists/List';
import Login from './Auth/Login';
import Admin from './Admin/Admin';
import Person from './Lists/Person';
import Activate from './Auth/Activate';
import * as ROUTES from '../helpers/routes';
import { ItemProvider } from '../contexts/Item';
import ChangePassword from './Auth/ChangePassword';
import ForgotPassword from './Auth/ForgotPassword';
import Navigation from '../components/Layout/Navigation';
import { AuthenticationProvider } from '../contexts/Authentication';
import People from './Lists/People';
import BottomNav from '../components/Layout/BottomNav';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(10),
    paddingTop: theme.spacing(11),
  },
}));

const App = () => {
  const classes = useStyles();

  return (
    <Router>
      <AuthenticationProvider>
        <ItemProvider>
          <Container maxWidth='sm' className={classes.root}>
            <CssBaseline />
            <Navigation className={classes.nav}/>
            <Routes>
              <Route exact path={ROUTES.HOME} element={<List />} />
              <Route path={ROUTES.LIST} element={<List />} />
              <Route path={ROUTES.PERSON} element={<Person />} />
              <Route path={ROUTES.PEOPLE} element={<People />} />
              <Route path={ROUTES.LOGIN} element={<Login />} />
              <Route path={ROUTES.ACTIVATE} element={<Activate />} />
              <Route path={ROUTES.CHANGE_PASSWORD} element={<ChangePassword />} />
              <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
              <Route path={ROUTES.ADMIN} element={<Admin />} />
            </Routes>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={5}>
              <BottomNav />
            </Paper>
          </Container>
        </ItemProvider>
      </AuthenticationProvider>
    </Router>
  )
};

export default App;