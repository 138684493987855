import React, { useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import { FirebaseContext } from '../../contexts/Firebase';
import { getEncryptedMatches } from '../../helpers/people';

const ActionDialog = ({ open, setOpen, onFinish }) => {
  const firebase = useContext(FirebaseContext);

  const confirm = () => {
    const matches = getEncryptedMatches();

    firebase.matches()
            .set(matches)
            .then(() => onFinish('Success'))
            .catch(() => onFinish('Error'));
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        Confirm Matching Action
      </DialogTitle>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button onClick={confirm} variant={'contained'}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActionDialog;