import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

// const config = {
//   apiKey: "AIzaSyALg_He2xvDq6stgAEoF2E_hMOA8gOqzoo",
//   authDomain: "rooke-secret-santa-v3-dev.firebaseapp.com",
//   databaseURL: "https://rooke-secret-santa-v3-dev.firebaseio.com",
//   projectId: "rooke-secret-santa-v3-dev",
//   storageBucket: "rooke-secret-santa-v3-dev.appspot.com",
//   messagingSenderId: "122227997148",
//   appId: "1:122227997148:web:9a526aa9ebeb8bb8"
// };

const config = {
  apiKey: "AIzaSyCYkCG9dwYXg-9ZEdOMWDJIvtADP8dP0b4",
  authDomain: "rooke-secret-santa-v3.firebaseapp.com",
  databaseURL: "https://rooke-secret-santa-v3.firebaseio.com",
  projectId: "rooke-secret-santa-v3",
  storageBucket: "rooke-secret-santa-v3.appspot.com",
  messagingSenderId: "983887013481",
  appId: "1:983887013481:web:0a4a698459a7a483"
}

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.database();
  }

  // AUTH FUNCTIONS
  createUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);
  signInUserWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);
  signOut = () => this.auth.signOut();
  resetPassword = email => this.auth.sendPasswordResetEmail(email);
  updatePassword = password => this.auth.currentUser.updatePassword(password);

  // DB FUNCTIONS
  user = uid => this.db.ref(`users/${uid}`);
  users = () => this.db.ref('users');

  list = name => this.db.ref(`lists/${name}`);
  lists = () => this.db.ref('lists');
  item = (name, id) => this.db.ref(`lists/${name}/${id}`);

  matches = () => this.db.ref('matches');
  match = (name) => this.db.ref(`matches/${name}`);
}

export default Firebase;