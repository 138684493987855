import React, { useState, useContext } from 'react';

import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';

import { FirebaseContext } from '../../contexts/Firebase';

const useStyles = makeStyles( () => ({
  firstInput: {
    marginTop: 0,
  },
}));

const Forgot = () => {
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const isInvalid = email === '';

  const submit = event => {
    event.preventDefault();
    firebase.resetPassword(email)
      .then(() => setEmail(''))
      .catch(error => setError(error));
  };

  return (
    <React.Fragment>
      <h3>Sign In</h3>
      <TextField
        id='email'
        label='Email'
        value={email}
        onChange={event => setEmail(event.target.value)}
        margin='normal'
        variant='outlined'
        fullWidth
        className={classes.firstInput}
      />
      { error && <p>{ error.message }</p>}
      <FormControl component={'div'} fullWidth>
        <Button
          color='primary'
          href='#'
          onClick={submit}
          variant='contained'
          disabled={isInvalid}
        >
          Reset Password
        </Button>
      </FormControl>
    </React.Fragment>
  );
};

export default Forgot;