import React, { createContext } from 'react';

import Firebase from '../helpers/Firebase';

const FirebaseContext = createContext(null);

const FirebaseProvider = ({ children }) => (
  <FirebaseContext.Provider value={new Firebase()}>
    {children}
  </FirebaseContext.Provider>
);


export { FirebaseContext, FirebaseProvider };