import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import './css/index.css';

import App from './pages/App';
import { FirebaseProvider } from './contexts/Firebase';
import * as serviceWorker from './helpers/serviceWorker';

const theme = createTheme();

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <FirebaseProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </FirebaseProvider>
  </StyledEngineProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
