import { useNavigate } from 'react-router-dom';
import React from 'react';

import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { AppBar, Toolbar, Slide } from '@mui/material';

import { HOME } from '../../helpers/routes';
import NavDrawer from './NavDrawer';

const useStyles = makeStyles( theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(1.5),
  },
  title: {
    paddingLeft: theme.spacing(3),
  }
}));

const HideOnScroll = (props) => {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
};

const Navigation = ({...props}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClickHome = () => navigate(HOME);

  return (
    <HideOnScroll {...props}>
      <AppBar color='primary'>
        <Grid container alignItems={'center'}>
          <Grid item xs={9} className={classes.title}>
            <Typography variant={'h6'} onClick={handleClickHome}>
              Rooke Secret Santa
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Toolbar className={classes.root}>
              <NavDrawer />
            </Toolbar>
          </Grid>
        </Grid>
      </AppBar>
    </HideOnScroll>
  );
};

export default Navigation;