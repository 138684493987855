import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import { Navigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import ROLES from '../../helpers/roles';
import { HOME, LOGIN } from '../../helpers/routes';
import { UsersProvider } from '../../contexts/Users';
import Actions from '../../components/Admin/Actions';
import Loading from '../../components/Shared/Loading';
import UserList from '../../components/Admin/UserList';
import { AuthenticationContext } from '../../contexts/Authentication';

const Admin = () => {
  const { authenticating, role, user } = useContext(AuthenticationContext);

  if (authenticating) {
    return <Loading />
  }

  if (!authenticating && !user) {
    return <Navigate to={LOGIN} />;
  }

  if (!authenticating && user && role !== ROLES.ADMIN) {
    return <Navigate to={HOME} />;
  }

  return (
    <UsersProvider>
      <Grid container item xs={12}>
        <Typography variant="h4">
          Users
        </Typography>
        <Actions />
        <UserList />
      </Grid>
    </UsersProvider>
  );
};

export default Admin;