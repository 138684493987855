import React from 'react';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(theme => ({
  loading: {
    marginTop: theme.typography.pxToRem(theme.spacing(4)),
  },
}));

const Loading = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.loading} direction='column' alignContent='center' container item xs={12}>
      <CircularProgress />
    </Grid>
  );
};

export default Loading;